import { ENVIRONMENT } from "@constants";
import { Brand } from "@graphql/types";
import React, { createContext, useContext } from "react";
import { Key } from "react-router/node_modules/@types/react";
import { useBrand } from "../BrandProvider/BrandProvider";
import { Ga4Event, TealiumEvent, TealiumProviderProps, Utag } from "./types";

let initialised = false;

declare const utag: Utag | undefined;
declare const window: Window &
  typeof globalThis & {
    hj: any;
  };
const isProduction = process.env.NODE_ENV === "production";
const envMap: { [key: string]: string } = {
  development: "dev",
  dev: "dev",
  uat: "qa",
  pre: "qa",
  prod: "prod",
};

export const TealiumContext = createContext<{
  trackEvent: (_event: TealiumEvent) => void;
  ga4TrackEvent: (_event: Ga4Event) => void;
  trackPageView: (_pathname: string) => void;
  updateDataLayer: (_values: Record<Key, string | undefined>) => void;
}>({
  trackEvent: () => {},
  ga4TrackEvent: () => {},
  trackPageView: () => {},
  updateDataLayer: () => {},
});

export const tealiumTrackingBSM = function (ldrive = false) {
  const env = envMap[ENVIRONMENT] || "prod";
  let a: HTMLScriptElement | string = `//tags.tiqcdn.com/utag/theaa/${
    ldrive ? "ruj-bsm-app" : "bsm"
  }/${env}/utag.js`;
  const b = document;
  const c = "script";
  const d = b.createElement(c);
  d.src = a;
  d.type = "text/java" + c;
  d.async = true;
  a = b.getElementsByTagName(c)[0];
  a.parentNode?.insertBefore(d, a);
};

export const tealiumTrackingAAD = function (ldrive = false) {
  const env = envMap[ENVIRONMENT];
  let a: HTMLScriptElement | string = `//tags.tiqcdn.com/utag/theaa/${
    ldrive ? "ruj-aa-app" : "driving-school"
  }/${env}/utag.js`;
  const b = document;
  const c = "script";
  const d = b.createElement(c);
  d.src = a;
  d.type = "text/java" + c;
  d.async = true;
  a = b.getElementsByTagName(c)[0];
  a.parentNode?.insertBefore(d, a);
};

export const trackPageView = (pathname: string) => {
  if (typeof utag !== "undefined") {
    utag.view({ page_name: pathname });
  }
};

export const getTrackEventFn = () => ({
  eventCategory,
  eventAction,
  eventLabel,
  eventValue,
}: TealiumEvent) => {
  if (typeof utag !== "undefined") {
    utag.link({
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
    });
  }
};

export const getGa4TrackEventFn = (values: Ga4Event) => {
  if (typeof window.gtag !== "undefined") {
    window.gtag("event", "interaction", {
      ...window.gtag,
      ...values,
    });
  }
};

export const updateDataLayer = (values: Record<Key, string | undefined>) => {
  if (typeof window.utag_data !== "undefined") {
    window.utag_data = {
      ...window.utag_data,
      ...values,
    };
  }
};

const TealiumProvider = ({
  trackEventFn,
  ga4TrackEventFn,
  children,
  ldrive = false,
}: TealiumProviderProps) => {
  const { brand } = useBrand();
  const trackEvent = trackEventFn || getTrackEventFn();
  const ga4TrackEvent = ga4TrackEventFn || getGa4TrackEventFn;

  if (isProduction && !initialised) {
    const initialiseTelium =
      brand === Brand.Aads ? tealiumTrackingAAD : tealiumTrackingBSM;
    initialiseTelium(ldrive);
    initialised = true;

    // Tealium expects this data to be available on the window, sorry
    window.utag_data = {
      page_type: "Journey",
    };
  }

  return (
    <TealiumContext.Provider
      value={{
        trackEvent,
        trackPageView,
        updateDataLayer,
        ga4TrackEvent,
      }}
    >
      {children}
    </TealiumContext.Provider>
  );
};

export const useTealium = () => useContext(TealiumContext);

export default TealiumProvider;
